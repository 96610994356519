<template>
  <div class="container">
    <div class="backWrap">
      <span class="back" @click="back">
        <i class="el-icon-arrow-left"></i> 返回
      </span>
      <template v-if="fromWhere == 'inputForm'">
        <router-link class="ml8" :to="{ path: '/OnSale/InputForm' }">修改编辑</router-link>
      </template>
    </div>
    <a-row class="commonWhiteBg detailView">
      <a-col :md="24" :lg="18" :xl="18" class="leftView">
        <div class="topTittle">{{goodsInfo.name}}</div>
        <div class="time">{{goodsInfo.createTimeText}}</div>
        <div class="topInfo">
          <img
            class="faceImg"
            :src="goodsInfo.images"
          >
          <div class="rightList">
            <p>论文名称：{{goodsInfo.name}}</p>
            <p>作者：{{goodsInfo.author}}</p>
            <p>格式：{{goodsInfo.extra.fileType}}</p>
            <p v-if="goodsInfo.goodsType=='00'">类型：电子书</p>
            <p v-else-if="goodsInfo.goodsType=='10'">类型：文章</p>
            <p v-else-if="goodsInfo.goodsType=='20'">类型：视频</p>
            <p v-else-if="goodsInfo.goodsType=='30'">类型：音频</p>
            <p>分类：{{goodsInfo.categoryName}}</p>
          </div>
        </div>

        <div class="leftTittle">
          摘要
        </div>
        <div
          class="desc"
          :class="{'NoseeAll':!seeAll.block1}"
        >
        {{goodsInfo.description}}
        </div>
        <div class="seeMore"> <a @click="seeMore('block1')"> {{seeAll.block1?'收起':'展开全部'}} </a> </div>

        <div class="leftTittle">
          关键词
        </div>
        <div class="desc">
        {{goodsInfo.keyword}}
        </div>

    
        <!-- <div class="leftTittle">目录</div>
        <div class="desc catalog" :class="{ NoseeAll: !seeAll.block2 }">
          <p>地球往事·三体</p>
          <p>地球往事·黑暗森林</p>
          <p>地球往事·死神永生</p>
          <p>地球往事·三体</p>
          <p>地球往事·黑暗森林</p>
          <p>地球往事·死神永生</p>
          <p>地球往事·三体</p>
          <p>地球往事·黑暗森林</p>
          <p>地球往事·死神永生</p>
          <p>地球往事·三体</p>
          <p>地球往事·黑暗森林</p>
          <p>地球往事·死神永生</p>
        </div>
        <div class="seeMore">
          <a class="seeMore" @click="seeMore('block2')">
            {{ seeAll.block2 ? "收起" : "展开全部" }}
          </a>
        </div> -->

        <div class="leftTittle">
          原文摘录
        </div>
        <div
          class="desc"
          :class="{'NoseeAll':!seeAll.block3}"
        >
          {{goodsInfo.extra.abstracts}}
        </div>
        <div class="seeMore"> <a
            class="seeMore"
            @click="seeMore('block3')"
          > {{seeAll.block3?'收起':'展开全部'}} </a></div>

        <template v-if="fromWhere == 'list'">
          <div class="leftTittle">推荐书籍</div>
          <ul class="recommendList clearfix">
            <li v-for="(item, index) in imgList" :key="index">
              <div class="img">
                <img :src="item.img" />
              </div>
              <div class="name">{{ item.name }}</div>
            </li>
          </ul>
        </template>
      </a-col>
      <a-col :md="24" :lg="6" :xl="6"  class="rightView">
        <div class="inner">
          <div class="btnWrap">
            <template v-if="fromWhere == 'inputForm'">
              <!-- 表单过来的 -->
              <!-- <div>
                <el-button type="primary">保存并发布</el-button>
              </div>
              <div>
                <el-button class="saveBtn">仅保存</el-button>
              </div> -->
              <div>
                <el-link target="_blank" :href="goodsInfo.downloadUrl" :underline="false">
                  <el-button type="primary">下载文件</el-button>
                </el-link>
              </div>
                  <div>
                <el-button class="saveBtn" @click="$router.push(`/Mine/OnSale`)">挂卖管理</el-button>
              </div>
            </template>
            <template v-if="fromWhere=='list'">
              <!-- 搜索列表过来的 -->
              <div>
                价格: <span class="price">{{goodsInfo.price}}</span> 元
                <el-button type="primary" @click="createOrder">获取授权</el-button>
                <!-- 已获取授权，可查看并下载 -->
              </div>
            </template>
          </div>
          <div class="grayTittle">
                存证证书
          </div>
            <div class="certificateImg">
              <el-image
                class="img"
                :src="orderInfo.certificateInfo.jpgUrl"
                :preview-src-list="[orderInfo.certificateInfo.jpgUrl]"
              >
              </el-image>
            </div>
            <div class="grayTittle">
              存证信息
          </div>
          <ul class="infoList">
               <li>
                <span class="label"> 作者 </span>
                <span class="value">
                  <span v-for="(item,index) in orderInfo.authors" :key="index"> {{item}} </span>
                </span>
              </li>
              <li>
                <span class="label"> 著作权人 </span>
                <span class="value"> {{orderInfo.certificateInfo.body.data.author}} </span>
              </li>
              <li>
                <span class="label"> 存证时间 </span>
                <span class="value"> {{orderInfo.createTimeText}} </span>
              </li>
              <li>
                <span class="label"> 上链地址 </span>
                  <a target="_blank" :href="'/certificate/'+orderInfo.certificateInfo.body.certificateCode"> 
                  http://www.cnpkp.com/certificate/{{orderInfo.certificateInfo.body.certificateCode}} 
                  </a>
              </li>
          </ul>
        </div>
      </a-col>
    </a-row>

    <agreement-dialog
      v-if="agreementDialogVisible"
      @close="onAgreementDialogClose"
    ></agreement-dialog>

     <goods-pay-dialog ref="payDialog" @close="onSetPayDialogClose"></goods-pay-dialog>
  </div>
</template>

<script>
import { queryGoods,applyOrder,paymentOrder } from "@/api/GoodsService";
import {getGoodInfo , queryExistOrder} from '@/api/EvidenceService'
import AgreementDialog from "./components/AgreementDialog";
import GoodsPayDialog from "@/views/multipleService/components/GoodsPayDialog";
import { mapGetters } from "vuex";
export default {
  name: "DetailView",
  components: { AgreementDialog,GoodsPayDialog },
  data() {
    return {
      goodsId: this.$route.query.goodsId,
      goods:null,
      existOrderNo:null,
      existOrder:null,
      seeAll: {
        block1: false,
        block2: false,
        block3: false,
      },
      certificateImg:
        "https://dbk-read.oss-cn-hangzhou.aliyuncs.com/1/1/2021/01/29/read/system/evidence/certificate/img/2/16777236/900-8324242-571389.jpg",
      imgList: [
        {
          img: "https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/bookFace.png",
          name: "三体",
        },
        {
          img: "https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/bookFace.png",
          name: "三体",
        },
        {
          img: "https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/bookFace.png",
          name: "三体",
        },
        {
          img: "https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/bookFace.png",
          name: "三体",
        },
        {
          img: "https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/bookFace.png",
          name: "三体",
        },
        {
          img: "https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/bookFace.png",
          name: "三体",
        },
        {
          img: "https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/bookFace.png",
          name: "三体",
        },
      ],
      fromWhere: "",
      agreementDialogVisible: false,
      goodsInfo: {extra: {} },
      orderInfo: {certificateInfo: {body:{data:{}},jpgUrl:'',},items: []},
    };
  },
  computed: {
    ...mapGetters(["mid", "userInfo", "evidencePaymentPrice", "vipInfo"]),
  },
  mounted() {
    const fromWhere = this.$route.query.fromWhere;
    if (!!fromWhere) {
      this.fromWhere = fromWhere;
    }
    this.fetchData();
  },
  methods: {
    back() {
      this.$router.go(-1); //返回上一层
    },
    fetchData () {
      console.log("商品id："+this.$route.query.goodsId);
      getGoodInfo(this.$route.query.goodsId).then(({ data }) => {
        // 获取商品信息商品
        console.log("获取商品信息：",data);
        this.goodsInfo = data;
        this.goodsInfo.createTimeText = this.dayjs(
          this.goodsInfo.createTime * 1000
        ).format("YYYY-MM-DD HH:mm:ss ");
        if(this.goodsInfo.images == '' || this.goodsInfo.images == undefined){
          this.goodsInfo.images = 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/bookFace2.png'
        }

        // 判断mid是否一致，一致表示是自己的作品，显示下载，隐藏付款
        // console.log('this.goodsInfo.mid:',this.goodsInfo.mid);
        // console.log('this.mid:',this.mid);
        if(this.fromWhere == 'list'){
          if(this.goodsInfo.mid == this.mid){
            this.fromWhere ='inputForm'
          }
        }

        console.log("存证证书id："+this.goodsInfo.extra.existOrderNo);
        queryExistOrder(this.goodsInfo.extra.existOrderNo).then(({ data }) => {
          console.log("获取商品存证信息：",data);
          this.orderInfo = data;
          this.orderInfo.createTimeText = this.dayjs(
            this.orderInfo.createTime * 1000
          ).format("YYYY-MM-DD HH:mm:ss ");
        })
      })
    },
    async createOrder(){
      let params={
        goodsId:this.goodsInfo.goodsId,
        quantity:1
      };
     let ret= await  applyOrder(params);
     console.log(ret);
     ret= await paymentOrder(ret.data.orderNo,{
       paymode:'wechat'
     });
     console.log('ret:',ret);

      this.$refs.payDialog.showDialog(ret.data,this.goodsInfo.price);
      this.isPayDialogVisible = true;

    },
    seeMore(key) {
      this.seeAll[key] = !this.seeAll[key];
    },
    onAgreementDialogClose() {
      this.agreementDialogVisible = false;
    },
     onSetPayDialogClose(result) {
      console.log(result);
      if (result) {
        // finish
        // this.loadOrder();
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.backWrap {
  padding: 8px 0 0;
}
.back{
  cursor: pointer;
}
.detailView {
  color: rgba(0, 18, 22, 0.85);
  padding-bottom: 28px;
  .leftView {
    padding: 0 24px;
    flex: 1;
    .topTittle {
      font-size: 24px;
      font-weight: bold;
    }
    .time {
      color: rgba(0, 18, 22, 0.65);
    }
    .topInfo {
      display: flex;
      margin-top: 32px;
      .faceImg {
        width: 135px;
        margin-right: 24px;
      }
      .rightList{
        padding: 10px 0;
      }
      .rightList p {
        margin-bottom: 12px;
      }
    }
    .leftTittle {
      color: #007DFF;
      font-size: 18px;
      margin: 24px 0 5px;
      font-weight: bold;
    }
    .desc {
      p {
        text-indent: 2em;
        margin-top: 16px;
      }
    }
    .NoseeAll {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
    }
    .seeMore {
      margin-top: 8px;
    }
    .catalog {
      p {
        margin-top: 2px;
        text-indent: 0;
      }
      & > p:first-child {
        margin-top: 16px;
      }
    }
    .recommendList {
      li {
        margin-top: 16px;
        float: left;
        width: 17%;
        margin-left: 3.75%;
        .name {
          margin-top: 4px;
          text-align: center;
        }
      }
      .img {
        width: 100%;
        img {
          display: block;
          width: 100%;
        }
      }
      & > li:nth-child(5n + 1) {
        margin-left: 0;
      }
    }
  }

  .rightView {
    .inner {
      position: relative;
      min-height: 572px;
      .btnWrap {
        padding: 0 8px;
        display: flex;
        justify-content: space-between;
        & > div {
          flex: 1;
          padding:0 8px;
          /deep/ .el-button {
            width: 100%;
          }
          .el-link{
            display: block;
            width: 100%;
          }
          .saveBtn {
            border-color:#007DFF;
            color:#007DFF;
          }
        }
        line-height: 30px;
        .price{
          color: #007DFF;
          font-size: 16px;
        }
      }
    }
    .grayTittle{
      font-size: 16px;
      color:rgba(0, 18, 22,.45);
      display: block;
      margin:24px 0 16px;
      padding:0 16px;
    }
    .infoList{
      padding:0 16px;
      li{
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .value{
          color:rgba(0, 18, 22,.65);
          max-width: 180px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        a{
          max-width: 180px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .certificateImg{
       padding:0 16px;
       img{
            display: block;
         width:100%;
       }
    }
    .inner::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      bottom: 0;
      background: #eee;
    }
  }
}
</style>
